import styles from 'components/svg/common.module.scss'

export const NoHiddenNewsYet = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="200" height="160" viewBox="0 0 200 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_2364_43248)">
      <g filter="url(#filter0_f_2364_43248)">
        <ellipse cx="101.874" cy="131.941" rx="71.8332" ry="12.9414" className={styles.fillPrimary300} />
      </g>
      <path
        d="M105 161C146.421 161 180 127.421 180 86C180 44.5786 146.421 11 105 11C63.5786 11 30 44.5786 30 86C30 127.421 63.5786 161 105 161Z"
        className={styles.fillPrimary200}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154 37.2857C155.262 37.2857 156.286 36.2624 156.286 35C156.286 33.7376 155.262 32.7143 154 32.7143C152.738 32.7143 151.714 33.7376 151.714 35C151.714 36.2624 152.738 37.2857 154 37.2857ZM154 39C156.209 39 158 37.2091 158 35C158 32.7909 156.209 31 154 31C151.791 31 150 32.7909 150 35C150 37.2091 151.791 39 154 39Z"
        className={styles.fillPrimary300}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M134 155.286C135.262 155.286 136.286 154.262 136.286 153C136.286 151.738 135.262 150.714 134 150.714C132.738 150.714 131.714 151.738 131.714 153C131.714 154.262 132.738 155.286 134 155.286ZM134 157C136.209 157 138 155.209 138 153C138 150.791 136.209 149 134 149C131.791 149 130 150.791 130 153C130 155.209 131.791 157 134 157Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M24.2985 130.031C26.0586 129.744 27.4816 131.525 26.8456 133.219L25.9838 135.515C25.3544 137.192 23.1761 137.526 22.0518 136.119L20.5283 134.211C19.4041 132.804 20.1712 130.703 21.9131 130.419L24.2985 130.031Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M172.067 96.8329C171.656 95.1412 173.209 93.6304 174.894 94.0804L178.289 94.9865C180.014 95.4469 180.582 97.6046 179.306 98.8468L176.737 101.347C175.461 102.589 173.312 101.97 172.892 100.239L172.067 96.8329Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M100.006 6.48074C100.538 4.50642 103.462 4.50642 103.994 6.48074L104.272 7.51546C104.466 8.23214 105.06 8.78506 105.813 8.94785L106.394 9.07335C108.535 9.53644 108.535 12.4636 106.394 12.9267L105.813 13.0522C105.06 13.2149 104.466 13.7679 104.272 14.4845L103.994 15.5193C103.462 17.4936 100.538 17.4936 100.006 15.5193L99.7275 14.4845C99.5344 13.7679 98.9396 13.2149 98.1867 13.0521L97.6063 12.9267C95.4646 12.4636 95.4646 9.53644 97.6063 9.07335L98.1867 8.94785C98.9396 8.78506 99.5344 8.23214 99.7275 7.51546L100.006 6.48074Z"
        className={styles.fillPrimary400}
      />
      <rect x="57" y="54" width="96" height="106" rx="8" fill="white" />
      <rect x="65" y="127" width="61" height="6" rx="3" className={styles.fillPrimary300} />
      <rect x="57" y="54" width="96" height="55" rx="8" className={styles.fillPrimary100} />
      <rect x="90" y="67" width="30" height="30" rx="15" className={styles.fillPrimary400} />
      <path
        d="M97.1757 74.1757C96.9627 74.3887 96.9434 74.7221 97.1176 74.957L97.1757 75.0243L100.403 78.2519C98.8658 79.3315 97.7166 80.9439 97.2391 82.8515C97.1586 83.173 97.354 83.4988 97.6754 83.5792C97.9969 83.6597 98.3227 83.4644 98.4032 83.1429C98.8268 81.4508 99.8729 80.0316 101.267 79.1156L102.715 80.5634C102.149 81.1406 101.8 81.9314 101.8 82.8037C101.8 84.571 103.233 86.0037 105 86.0037C105.872 86.0037 106.663 85.6547 107.24 85.0887L111.976 89.8243C112.21 90.0586 112.59 90.0586 112.824 89.8243C113.037 89.6113 113.057 89.2779 112.882 89.043L112.824 88.9757L107.933 84.0844L107.934 84.0832L106.974 83.1249L104.678 80.8294L104.68 80.8288L102.375 78.5263L102.376 78.5248L101.469 77.6204L98.0243 74.1757C97.7899 73.9414 97.4101 73.9414 97.1757 74.1757ZM103.563 81.4124L106.391 84.2404C106.031 84.5891 105.541 84.8037 105 84.8037C103.895 84.8037 103 83.9083 103 82.8037C103 82.263 103.215 81.7724 103.563 81.4124ZM105 76.8C104.2 76.8 103.423 76.9185 102.689 77.14L103.678 78.129C104.107 78.0443 104.549 78 105 78C108.138 78 110.848 80.1442 111.598 83.1465C111.678 83.468 112.004 83.6636 112.325 83.5833C112.647 83.503 112.842 83.1773 112.762 82.8558C111.879 79.3222 108.692 76.8 105 76.8ZM105.156 79.6074L108.197 82.648C108.115 81.0025 106.798 79.6861 105.156 79.6074Z"
        fill="white"
      />
      <rect x="65" y="137" width="54" height="6" rx="3" className={styles.fillPrimary300} />
      <rect x="65" y="147" width="45" height="6" rx="3" className={styles.fillPrimary300} />
      <rect x="65" y="117" width="34" height="6" rx="3" className={styles.fillPrimary200} />
      <rect x="65" y="157" width="43" height="6" rx="3" className={styles.fillPrimary200} />
      <path
        d="M30.0064 95.4807C30.5384 93.5064 33.4616 93.5064 33.9936 95.4807L34.2725 96.5155C34.4656 97.2321 35.0604 97.7851 35.8133 97.9478L36.3937 98.0733C38.5354 98.5364 38.5354 101.464 36.3937 101.927L35.8133 102.052C35.0604 102.215 34.4656 102.768 34.2725 103.485L33.9936 104.519C33.4616 106.494 30.5384 106.494 30.0064 104.519L29.7275 103.485C29.5344 102.768 28.9396 102.215 28.1867 102.052L27.6063 101.927C25.4646 101.464 25.4646 98.5364 27.6063 98.0733L28.1867 97.9478C28.9396 97.7851 29.5344 97.2321 29.7275 96.5155L30.0064 95.4807Z"
        className={styles.fillPrimary400}
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2364_43248"
        x="8.26802"
        y="97.227"
        width="187.212"
        height="69.4288"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_2364_43248" />
      </filter>
      <clipPath id="clip0_2364_43248">
        <rect width="200" height="160" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
