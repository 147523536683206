import styles from 'components/svg/common.module.scss'

export const NoNewsAvailable = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="160" viewBox="0 0 200 160" fill="none" {...props}>
    <g clip-path="url(#clip0_2364_43228)">
      <g filter="url(#filter0_f_2364_43228)">
        <ellipse cx="101.874" cy="131.941" rx="71.8332" ry="12.9414" className={styles.fillPrimary300} />
      </g>
      <path
        d="M105 161C146.421 161 180 127.421 180 86C180 44.5786 146.421 11 105 11C63.5786 11 30 44.5786 30 86C30 127.421 63.5786 161 105 161Z"
        className={styles.fillPrimary200}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154 37.2857C155.262 37.2857 156.286 36.2624 156.286 35C156.286 33.7376 155.262 32.7143 154 32.7143C152.738 32.7143 151.714 33.7376 151.714 35C151.714 36.2624 152.738 37.2857 154 37.2857ZM154 39C156.209 39 158 37.2091 158 35C158 32.7909 156.209 31 154 31C151.791 31 150 32.7909 150 35C150 37.2091 151.791 39 154 39Z"
        className={styles.fillPrimary300}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M134 155.286C135.262 155.286 136.286 154.262 136.286 153C136.286 151.738 135.262 150.714 134 150.714C132.738 150.714 131.714 151.738 131.714 153C131.714 154.262 132.738 155.286 134 155.286ZM134 157C136.209 157 138 155.209 138 153C138 150.791 136.209 149 134 149C131.791 149 130 150.791 130 153C130 155.209 131.791 157 134 157Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M24.2985 130.031C26.0586 129.744 27.4816 131.525 26.8456 133.219L25.9838 135.515C25.3544 137.192 23.1761 137.526 22.0518 136.119L20.5283 134.211C19.4041 132.804 20.1712 130.703 21.9131 130.419L24.2985 130.031Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M172.067 96.8329C171.656 95.1412 173.209 93.6304 174.894 94.0804L178.289 94.9865C180.014 95.4469 180.582 97.6046 179.306 98.8468L176.737 101.347C175.461 102.589 173.312 101.97 172.892 100.239L172.067 96.8329Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M100.006 6.48074C100.538 4.50642 103.462 4.50642 103.994 6.48074L104.272 7.51546C104.466 8.23214 105.06 8.78506 105.813 8.94785L106.394 9.07335C108.535 9.53644 108.535 12.4636 106.394 12.9267L105.813 13.0522C105.06 13.2149 104.466 13.7679 104.272 14.4845L103.994 15.5193C103.462 17.4936 100.538 17.4936 100.006 15.5193L99.7275 14.4845C99.5344 13.7679 98.9396 13.2149 98.1867 13.0521L97.6063 12.9267C95.4646 12.4636 95.4646 9.53644 97.6063 9.07335L98.1867 8.94785C98.9396 8.78506 99.5344 8.23214 99.7275 7.51546L100.006 6.48074Z"
        className={styles.fillPrimary400}
      />
      <rect x="57" y="54" width="96" height="106" rx="8" fill="white" />
      <rect x="65" y="127" width="80" height="6" rx="3" className={styles.fillPrimary400} />
      <rect x="57" y="54" width="96" height="55" rx="8" className={styles.fillPrimary100} />
      <rect x="65" y="137" width="71" height="6" rx="3" className={styles.fillPrimary400} />
      <rect x="65" y="147" width="45" height="6" rx="3" className={styles.fillPrimary400} />
      <rect x="65" y="117" width="34" height="6" rx="3" className={styles.fillPrimary200} />
      <rect x="65" y="157" width="43" height="6" rx="3" className={styles.fillPrimary200} />
      <path
        d="M30.0064 95.4807C30.5384 93.5064 33.4616 93.5064 33.9936 95.4807L34.2725 96.5155C34.4656 97.2321 35.0604 97.7851 35.8133 97.9478L36.3937 98.0733C38.5354 98.5364 38.5354 101.464 36.3937 101.927L35.8133 102.052C35.0604 102.215 34.4656 102.768 34.2725 103.485L33.9936 104.519C33.4616 106.494 30.5384 106.494 30.0064 104.519L29.7275 103.485C29.5344 102.768 28.9396 102.215 28.1867 102.052L27.6063 101.927C25.4646 101.464 25.4646 98.5364 27.6063 98.0733L28.1867 97.9478C28.9396 97.7851 29.5344 97.2321 29.7275 96.5155L30.0064 95.4807Z"
        className={styles.fillPrimary400}
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2364_43228"
        x="8.26778"
        y="97.227"
        width="187.212"
        height="69.4289"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_2364_43228" />
      </filter>
      <clipPath id="clip0_2364_43228">
        <rect width="200" height="160" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
